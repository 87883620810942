@use "sass:math"; @import "variables.scss";
.detailedViewInner {
  padding: grid-gap(1.5) grid-gap(1.75) 0;
}

.box {
  margin: 0 0 grid-gap(2);
}

.labelWrapper {
  margin: 0 0 grid-gap(2);
}

.sectionTitle {
  color: $color-neutrals-800-dark;
  font-size: $font-size-extra-large;
  font-weight: $font-weight-semi-bold;
  line-height: 24px;
  margin: 0 0 grid-gap(1);
}

.deliveryBox {
  padding: grid-gap(1);
  background-color: $color-brand-50-blue;
  border-radius: 6px;
  display: flex;

  .graphBox {
    width: 45%;
    padding-right: grid-gap(1);
  }

  .infoBox {
    width: 55%;
  }
}

.bigText {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-large;
  line-height: 24px;

  .smallSubText,
  .separator {
    color: $color-neutrals-700-dark-grey;
    font-size: $font-size-normal;
  }

  .separator {
    padding: 0 grid-gap(0.2);
  }
}

.smallText {
  color: $color-neutrals-700-dark-grey;
  text-transform: uppercase;
  font-size: $font-size-small;
  line-height: 16px;
}

.title {
  display: flex;
  align-items: center;
}

.capitalizedText {
  text-transform: capitalize;
}

.statsInner {
  display: flex;
  flex-wrap: wrap;
}

.statBox {
  display: flex;
  width: 50%;
  margin: 0 0 grid-gap(1.5);

  .iconWrapper {
    background-color: $color-brand-50-blue;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: grid-gap(0.75);
  }

  .icon {
    width: 16px;

    g,
    path {
      stroke: $color-brand-600-primary-blue;
      stroke-width: 2px;
    }
  }
}

.loaderBox {
  margin-top: grid-gap(0.35);
  border-radius: 4px;
  max-width: 100%;
}
