@use "sass:math"; @import "variables.scss";
$header-vertical-padding: grid-gap(2);
$header-content-height: grid-gap(6.5);

$tab-height: grid-gap(2.5);

$button-container-margin: grid-gap(2);
$button-margin: grid-gap(1);

.innerBox {
  padding: grid-gap(1.5) $header-vertical-padding grid-gap(1.5) $header-vertical-padding;
  height: $header-content-height;
}

.title {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  color: $color-neutrals-800-dark;
  line-height: 24px;
  margin-bottom: grid-gap(0.5);
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.accordion {
  z-index: 2;
  position: relative;
}

.descriptionTxt {
  @include source-sans-pro("regular");

  font-size: $font-size-large;
  color: $color-neutrals-700-dark-grey;
  font-weight: $font-weight-normal;
  line-height: 24px;
}

.buttonContainer {
  padding: $button-container-margin $button-container-margin grid-gap(1.5) $button-container-margin;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  background-color: $color-neutrals-100-white;
  bottom: 0;
  width: calc(100% - #{grid-gap(4)});
  z-index: 1;

  .small {
    flex-basis: calc(100%/2 - 8px);
  }
}

.accordionItem {
  width: 100%;
  border: none;
  margin: 0;
  border-bottom: solid 1px $color-neutrals-400-cold-grey;
  border-radius: 0;

  button {
    padding: 0;
  }

  svg {
    transition: all 0.5s;
    margin-right: 0;
  }
}

.accordionHeader {
  display: flex;
  width: 100%;
  height: $tab-height;
  background-color: $color-brand-50-blue;
  color: inherit;
  border: none;
  font: inherit;
  outline: inherit;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 grid-gap(1) 0 grid-gap(1.25);
  box-sizing: border-box;

  &:hover {
    background-color: $color-brand-100-blue;
  }
}

.accordionLabelContainer {
  @include source-sans-pro("regular");

  color: $color-neutrals-800-dark;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-large;
  text-align: left;
  font-style: normal;
  line-height: 24px;
  flex: 1;
}

.notification {
  width: 400px;
  box-sizing: border-box;
}
