@use "sass:math"; @import "variables.scss";
.smallTextLoader {
  margin: grid-gap(0.2) 0 0;
}

.titleLoader {
  margin: grid-gap(0.35) 0 0;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: $color-neutrals-400-cold-grey;
  margin: 0 grid-gap(0.5);
}

.labelWrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 0 grid-gap(2);
}

.statusLabel {
  [class^="statusName__"] {
    font-size: $font-size-normal;
    font-weight: $font-weight-semi-bold;
  }
}

.campaignName {
  @include f-grotesk("demi");

  font-size: 28px;
  line-height: 32px;
  margin: 0 0 grid-gap(0.5);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.textInfoOverview {
  display: flex;
  align-items: center;
}

.companyIdBox,
.companyNameBox,
.personNameBox {
  color: $color-neutrals-700-dark-grey;
  font-size: $font-size-large;
  line-height: 24px;
  box-sizing: border-box;

  &.emptyBox {
    width: 50px;
    text-align: center;
  }
}

.companyNameBox,
.personNameBox {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;

  > div {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.personNameBox {
  &.emptyBox {
    width: 50px;
  }
}

.infoBox {
  font-size: $font-size-normal;
  background-color: $color-neutrals-800-dark;
  color: $color-white;
  padding: grid-gap(1);
  border-radius: 6px;
}
