@use "sass:math"; @import "variables.scss";
.innerBox {
  padding: grid-gap(1.5) grid-gap(2) 0;
}

.title {
  @include f-grotesk("demi");

  font-size: $font-size-extra-large;
  color: $color-neutrals-800-dark;
  line-height: 24px;
  margin-bottom: grid-gap(1);
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.descriptionTxt {
  font-size: $font-size-large;
  color: $color-neutrals-700-dark-grey;
  font-weight: $font-weight-normal;
  line-height: 24px;
  margin-bottom: grid-gap(2);
}

.fieldBox {
  margin-bottom: grid-gap(1.5);
}

.btnsBox {
  padding-top: grid-gap(1.5);
  border-top: solid 1px $color-neutrals-400-cold-grey;
  position: sticky;
  bottom: 0;
  background-color: $color-white;
  padding-bottom: grid-gap(1.5);
}

.topBox {
  margin-bottom: grid-gap(1);
}

.bottomBox {
  display: flex;
}

.btnApply {
  width: 100%;
  display: block;
}

.btnHalfWidth {
  width: 50%;
  display: block;

  &:nth-child(1) {
    margin-right: grid-gap(0.5);
  }

  &:nth-child(2) {
    margin-left: grid-gap(0.5);
  }
}
